exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-colchones-js": () => import("./../../../src/pages/colchones.js" /* webpackChunkName: "component---src-pages-colchones-js" */),
  "component---src-pages-deals-js": () => import("./../../../src/pages/deals.js" /* webpackChunkName: "component---src-pages-deals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-templates-colchon-js": () => import("./../../../src/templates/colchon.js" /* webpackChunkName: "component---src-templates-colchon-js" */),
  "component---src-templates-compare-js": () => import("./../../../src/templates/compare.js" /* webpackChunkName: "component---src-templates-compare-js" */)
}

